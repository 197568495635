.drop-down {
  display: flex;
  flex-direction: row;
  align-items: center;

  &--component {
    &--wrapper {
      .count {
        margin-top: 5px;
        width: 40%;
        text-align: center;
        background: #E0E0E0;
        color: #fff;
        font-weight: 700;
      }

      &--items-count {
        display: none;
      }
    }
  }

  .drop-down-component {
    min-width: 130px;

    &--label {
      color: #717171;
      font-size: 13px;
      font-weight: 500;
      min-width: 55px;
      margin-right: 10px;
    }

    .ant-select-selector {
      background: #F7F7F7 !important;
      color: #717171;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      padding: 0 12.5px !important;

      .ant-select-selection-item {
        padding-right: 12px !important;
      }
    }

    .ant-select-arrow {
      span {
        svg {
          width: 9px !important;
          height: 9px !important;
          margin: 2px 0 0 0 !important;
        }
      }
    }
  }

  &--component--label-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--image {
      margin: 0 4px 0 0;
    }
  }

  &-component--category-select {
    .mobile-filter-drawer__header {
      padding: 16px 20px;
    }
    .drop-down-component--no-padding .ant-select-item {
      padding: 0;
    }

    .mobile-filter-drawer__main-nav-link {
      padding: 16px;
    }
    .mobile-filter-drawer__main-nav-label {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 192px;
      white-space: nowrap;
    }

    &--main-option {
      padding: 0;
    }
  }
}

.ant-select-dropdown {
  .drop-down {
    &--component {
      &--wrapper {
        display: flex;
        justify-content: space-between;

        &--title {
          width: 75%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--items-count {
          display: block;
        }
      }
    }
  }
}
