.icon-component {
  display: block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  width: 16px;
  height: 16px;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizeLegibility;
  transition: .2s all;
  -webkit-font-smoothing: antialiased;

  svg {
    width: 100%;
    height: 100%;
  }

  &__arrow {
    transition: .2s all;

    &-bottom {
      transform: rotate(180deg);
    }

    &-left {
      transform: rotate(90deg);
    }

    &-right {
      transform: rotate(-90deg);
    }
  }
}
