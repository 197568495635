.save-search-button {
  display: inline;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  color: #FF0000;
  background: #F7F7F7 !important;
  padding: 4px 8px;
  border-radius: 4px;
  position: relative;
  max-width: 320px;

  &.disabled {
    opacity: 0.8;
    cursor: default;
  }

  &--container {
    display: flex;
    align-items: center;
  }

  &--tooltip {
    background-color: #004F57;
    border-radius: 50%;
    color: #ffffff;
    font-size: 10px;
    padding: 0 6px;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }
}

.save-search-modal {
  &--body {
    max-width: 400px;
    margin: 0 auto;
    padding: 24px;
    text-align: center;
  }

  &--icon {
    width: 50px;
  }

  &--text {
    margin: 24px 0 36px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
  }

  &--link {
    margin-top: 16px;
    opacity: 0.8;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    span {
      margin-left: 4px;
      color: #F00;
      cursor: pointer;
    }
  }

  .ant-modal-title {
    font-weight: 600;
    padding-right: 24px;
  }

  .ant-input {
    height: 40px;
  }

  .ant-btn {
    width: 100%;
    height: 40px;
  }
}
