.add-to-favorite-modal {
  .ant-modal-body {
    padding: 0 26px 24px !important;
  }

  &--input,
  &--select {
    width: 100%;
  }

  &--btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 8px;
    margin-top: 12px;
  }

  &--btn {
    background-color: #fff;
    border-color: #f00;

    span {
      color: #f00;
    }

    &:hover {
      span {
        color: #fff;
      }
    }
  }

  &--create-new-label {
    margin: 0;
    color: #f00;
    text-decoration: none;
    background-color: #0000;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }

  &--input {
    border-color: #f7f7f7;
  }

  &--input-error {
    margin-top: -6px;
    margin-left: 2px;
  }

  &--text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 500;
  }

  &--form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 8px;
  }

  &--cancel {
    background-color: #fff !important;

    span {
      color: #ff0000 !important;
    }

    &:hover {
      span {
        color: #fff;
      }
    }
  }
}
