.directory-ads-banner {
  width: 100%;
  padding: 16px;
  border-radius: 4px;

  background-color: rgba(0, 79, 87, 0.1);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  &--image {
    width: 100%;
    max-width: 220px;
  }

  &--title {
    margin: 0;
    color: #004f57;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  }

  &--link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 220px;
  }
}
