.home-item {
  min-height: 420px;
  border: 2px solid #F1F1F1;
  border-radius: 4px;
  background-color: #F7F7F7;
  padding: 12px 12px 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 250px;

  &:hover {
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.15);
    cursor: auto;
  }

  &.skeleton {
    .ant-skeleton-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-skeleton-paragraph {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &--image-holder {
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding-bottom: 10px;
    box-sizing: border-box;
    position: relative;
  }

  &--image-container {
    position: relative;
  }

  &--svg-heart-container {
    position: absolute;
    right: 6px;
    top: 6px;

    &__mobile {
      right: 10px;
      top: 10px;
    }
  }

  &--image {
    border: 4px solid #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }

  &--content-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    max-width: 100%;
  }

  &--owner, &--owner-mobile {
    color: rgba(0,0,0,0.6);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  &--owner {
    text-transform: capitalize;
    text-align: center;
  }

  &--owner-mobile {
    text-transform: uppercase;
    margin-top: 7px;
  }

  &--title {
    color: rgba(0,0,0,0.85);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
    word-wrap: break-word;
  }

  &--item-price {
    &--sold {
      margin: 16px 0 0 0;
      color: #FF0000;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;

      &.mobile {
        text-align: left;
        font-size: 22px;
        line-height: 25px;
      }
    }

    &--secondary-price-value {
      opacity: 0.5;
      color: rgba(0,0,0,0.85);
      font-size: 18px;
      letter-spacing: 0;
      line-height: 23px;
      margin: 0 16px 0 0;
    }
  }

  &--price, &--price-mobile {
    color: #FF0000;
    font-size: 26px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 0;
  }

  &--price {
    text-align: center;
  }

  &--price-mobile {
    text-align: left;
  }

  &--sub-price {
    color: rgba(0,0,0,0.5);
    line-height: 20px;
    margin-bottom: 0;
  }

  .justify-around div:last-of-type {
    margin: 0;
  }

  &--mobile-container {
    margin-top: -12px;
    padding-right: 10px;
    background-color: #F7F7F7;
    border-radius: 4px;
    border: 1px solid #F1F1F1;

    .image-container {
      background-color: #ffffff;
      border-right: 1px solid #F1F1F1;
      margin-right: 5px;
      height: 100%;

      &--image {
        max-width: 100%;
        max-height: 200px;
        object-fit: contain;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .content-container {
      padding: 10px;

      &--title {
        font-weight: 600;
        color: rgb(74, 74, 74);
        line-height: 19px;
        margin-bottom: 5px;
        word-wrap: break-word;
      }
    }
  }
}

.favorites-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 4px 4px;
  background-color:rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
}
