.category-page-bottom {
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-top: 45px;
  padding: 50px;

  &--block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

   p {
    margin: 0;
  }
}
