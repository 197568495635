@import "../../_common/variables";

.category {
  position: relative;
  min-height: 100vh;

  .red-spinner {
    left: 0;
  }

  &--show-more-button {
    z-index: 1;

    position: absolute;
    bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    font-size: 16px;
  }

  &--show-more-button-icon {
    margin-left: 5px;
  }

  &--sidebars-section {
    min-height: 1000px;
  }

  &--hidden-urls {
    display: none;
  }

  &--info-banner {
    position: relative;
    background-color: #004c52;
    border-radius: 4px;
    height: 150px;

    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 14.1367%;

      @media screen and (max-width: 800px) {
        padding-top: 41%;
      }

      @media screen and (max-width: 400px) {
        padding-top: 56%;
      }
    }
  }

  &--info-banner-image {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
    object-position: right;
    width: 100%;
    height: 100%;
  }

  &--info-banner-search {
    position: relative;
    margin: 20px auto 0;
    width: 100%;

    .ant-select-auto-complete {
      width: 100%;
    }

    .ant-input {
      height: 40px !important;
      border-color: #438C93 !important;
      box-shadow: none !important;
    }

    .ant-input-group-addon .ant-btn {
      background-color: #438C93;
      border: none;
      border-radius: 0 4px 4px 0 !important;
    }
  }

  &--info-title {
    position: absolute;
    top: 24px;
    left: 24px;
    margin: 0;

    > * {
      margin: 0;
      color: #FFF;
      font-size: 40px;
      font-weight: 500;
      line-height: 40px;
    }

    @media screen and (max-width: 800px) {
      > * {
        font-size: 30px;
      }
    }

    @media screen and (max-width: 767px) {
      top: unset;
      left: unset;
      padding: 0 20px;
      margin-top: 20px;
      display: inline-block;
      width: 100%;

      > * {
        line-height: 36px;
      }
    }

    @media screen and (max-width: 540px) {
      top: 50%;
      transform: translateY(-50%);
      left: unset;
      margin: 0;
      padding: 5px 20px;
      display: inline-flex;
      flex-wrap: wrap;

      > * {
        font-size: 28px;
        line-height: 30px;
      }

      &.category--info-title__big-text {
        margin: 0;

        > * {
          max-height: 114px;
          font-size: 24px;
          line-height: 28px;
          overflow: hidden;
        }
      }
    }

    &__big-text {
      margin-top: 5px;

      > * {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  &--info-description {
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    opacity: 0.8;
    overflow: hidden;
    max-height: 0;
    transition: all .2s;
    margin-bottom: 20px;

    &_open {
      overflow: visible;
      min-height: 75px;
      max-height: unset;
      margin: 20px 0;
      font-size: 16px;
      line-height: 25px;

      p {
        margin-bottom: 0;
      }
    }
  }

  &--info-items-count {
    margin: 10px 0 -10px;

    span {
      margin: 0 4px;
      color: #f00;
    }
  }

  &--items {
    border-top: 1px solid rgba(0,0,0,0.25);
    margin-top: 20px;
    padding-top: 18px;
  }

  &--breadcrumbs {
    min-height: 65px;
  }

  &--items-header {
    display: grid;
    grid-template-columns: auto 1fr;

    &__one-column {
      grid-template-columns: 100%;
    }

    .ant-breadcrumb {
      margin: 20px 0;
      flex-grow: 1;

      &--item {
        display: inline-block;
        height: 10px;

        h2 {
          font-size: 16px;
          color: rgba(0,0,0,.45);
        }
      }

      span:last-child .ant-breadcrumb--item h2 {
        color: #000000;
        cursor: default;
      }
    }

    .drop-down {
      &.antiques-sold {
        &:first-child {
          margin-right: 0 !important;
        }
      }
    }

    .drop-down-component--label {
      font-size: 16px !important;
      white-space: nowrap;
    }

    .drop-down-component>.ant-select-selector {
      font-size: 16px !important;
    }

    @media screen and (max-width: 760px) {
      grid-template-columns: 100%;

      .ant-breadcrumb {
        width: 100%;
        margin: 20px 0;
      }

      .category--filters-row--mobile-filter {
        justify-content: flex-start;
      }
    }

    @media screen and (max-width: 780px) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      .save-search-button--container {
        margin-bottom: 20px;
      }
    }
  }

  &--items-wrapper {
    margin-top: 20px;
  }

  &--item {
    max-width: 100%;
  }

  &--items-pagination {
    text-align: center;
    margin-top: 25px !important;
  }

  .home-sidebar--links {
    max-height: 565px;
    overflow: auto;

    &.similar-searches-links-container {
      height: 328px;
    }
  }

  .cat-page-latest--items {
    overflow-y: auto;

    @media screen and (max-width: $sm) {
        max-height: 280px;
    }
  }

  &--no-items-hint {
    margin: 20px;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
  }

  &--filters-row {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    &--items-count {
      .drop-down-component {
        min-width: unset;
        width: 60px;

        &--label {
          min-width: 40px;
        }
      }
    }

    &--mobile-filter {
      display: none;
      grid-template-columns: 150px 150px;
      grid-gap: 15px;
      justify-content: flex-end;

      .ant-select {
        width: 100%;
      }

      .ant-select-selector {
        height: 34px !important;
        background-color: #F7F7F7 !important;
        border: 1px solid #f1f1f1 !important;
        font-size: 14px !important;
      }

      .filter-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ant-select-arrow {
          top: unset;
          margin-top: 0;
        }
      }

      @media screen and (max-width: 1200px){
        display: grid;
      }

      @media screen and (max-width: 576px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .filter-box {
          width: 49%;
        }

        .ant-select-selection-placeholder {
          line-height: 32px !important;
        }
      }
      &__container {
        display: grid;
        grid-template-columns: min-content;

        .category__filter-tag {
          min-width: 140px;
          display: inline-flex;
        }

        @media screen and (max-width: 760px) {
          grid-template-columns: 100%;
          flex: 0 0 100%;
          width: 100%;
        }
      }
    }

    &--mobile-hidden {
      @media screen and (max-width: 580px) {
        display: none;
      }
    }

    &--desktop-filter {
      .ant-select-dropdown .drop-down--component--wrapper--title {
        width: 85%;
      }

      &.subcategory-dropdown {

        .ant-select {
          width: 240px;
          max-width: unset;

          .drop-down--component--wrapper {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      flex: 0 0 100%;
      justify-content: space-between;
      min-height: 46px;
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 5px;


    @media screen and (max-width: 760px) {

      .category__filter-tag {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 576px) {
      margin-top: 0;
    }
  }

  &__filter-tag-content {
    width: 95%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
  }

  &__filter-tag {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 5px;
    margin-right: 0;
    padding: 8px 8px;
    border: 1px solid #979797 !important;
    background-color: #FFF !important;

    font-size: 14px;
    line-height: 18px;
    color: #000 !important;

    max-width: 400px;

    .anticon {
      color: #000;
    }

    svg {
      width: 14px;
      height: 14px;
    }

    &_first {
      margin-right: 5px;
    }
  }


  &__filter-tag-title {
    color: rgba(113, 113, 113, 0.85);
  }
}

.mobile-filter-drawer {
  &.dropdown-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-body .ant-menu .ant-menu-item {
    height: 40px !important;
  }

  .ant-btn {
    padding: 0 20px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn[disabled] {
    background: red;
    border: unset;
    opacity: .4;
  }

  &.dropdown-drawer .ant-drawer-content-wrapper {
    &:not(.ant-drawer-content-wrapper-hidden) {
      max-height: 70% !important;
      background-color: #FFFFFF;
      display: flex;
    }
  }

  &.ant-drawer .ant-drawer-content {
    height: unset;
  }

  &__header {
    padding: 24px 24px 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);

    &_grid {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 13px;
    }
  }

  &__main-nav-link {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all .15s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__body {
    height: 100%;
    overflow: auto;
    padding-bottom: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .ant-menu-title-content {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__link-label {
    width: 85%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
  }

  .dropdown-drawer__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__filter-inputs-grid {
    padding: 24px 24px 50px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;

    position: relative;
  }

  &__filter-apply-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding: 15px 0;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__filter-input {
    border: 0;
    border-radius: 0;
    padding-left: 2px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &_search {
      border-bottom: 0;
      padding: 0 24px;

      .ant-input {
        height: 40px !important;
        border-color: #438C93 !important;
        box-shadow: none !important;
      }

      .ant-input-group-addon .ant-btn {
        background-color: #438C93;
        border: none;
        border-radius: 0 4px 4px 0 !important;
      }

      .ant-input-search-button {
        height: 40px;
        width: 40px;
      }
    }
  }

  &__filter-input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__filter-input-label {
    color: #9e9e9e;
    margin-right: 5px;
  }

  &__filter-error {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0 24px;
    text-align: left;
    line-height: 14px;
    font-size: 14px;

    color: red;
    font-weight: 400;
  }
}

.subcategory-dropdown__option {
  .drop-down--component--wrapper--title {
    width: 85%;
    line-break: anywhere;
  }
}

.no-scroll {
  max-height: none !important;
}
